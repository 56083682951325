import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, computed, reactive, watchEffect,
} from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line import/prefer-default-export
export default function commercialDetails() {
  const toast = useToast()

  const paymentDetailsData = ref({
    paymentType: [
      { value: null, text: 'Please select option' },
      { value: 'a', text: 'Credit Card' },
      { value: 'b', text: 'Bank Account' },
    ],
    selectedPaymentType: null,
    businessName: null,
    legalName: null,
    transitNumber: null,
    institutionNumber: null,
    accountNumber: null,
    charitableNumber: null,
    incorporationNumber: null,
    accountHolderName: null,
    accountHolderPosition: null,
    accountHolderdofb: null,
  })

  const commercialDetailsData = ref({
    name: null,
    lat: '30.044420',
    lng: '31.235712',
    logo: null,
    cover: null,
    about: null,
    phone_number: null,
    email: null,
    social_facebook: null,
    social_twitter: null,
    social_instagram: null,
    social_youtube: null,
    website: null,
    class_id: null,
    sub_class_id: null,
    address_type: 1,
    street: '',
    city: '',
    state: '',
    country: '',
    postal_code: '',
    service_name: [],
    service_description: [],
    language_id: null,
    tag_id: null,
    payment_type: null,
    business_type: null,
    business_name: null,
    legal_name: null,
    account_holder_name: null,
    account_holder_position: null,
    account_holder_dofb: null,
    transit_number: null,
    institution_number: null,
    account_number: null,
    phoneNum: null,
    charitable_number: null,
    donation_cats_id: null,
  })

  const addCommercial = () => {
    store.dispatch('commercialModule/addComm', commercialDetailsData.value).then(res => {
      if (res.data.status === 200) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Added Successfully',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
        router.push('/commercial')
      // eslint-disable-next-line no-empty
      } else {}
    }).catch(err => {
      if (err.response.data.errors.name) {
        console.log(err.response.data.errors)
        toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.errors.name.toString(),
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      } if (err.response.data.errors.phone_number) {
        toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.errors.phone_number.toString(),
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      } if (err.response.data.errors.email) {
        toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.errors.email.toString(),
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      }
    })
  }

  const getLang = () => store.dispatch('commercialModule/getLanguages')
  getLang()

  const languages = computed(() => store.state.commercialModule.languages)

  const getTags = () => store.dispatch('commercialModule/getTags')
  getTags()

  const tags = computed(() => store.state.commercialModule.tags)

  const getDC = () => store.dispatch('commercialModule/getDonationCategories')
  getDC()

  const DC = computed(() => store.state.commercialModule.donationCategories)

  const getClass = () => store.dispatch('commercialModule/getClassification')
  getClass()

  const classifications = computed(() => store.state.commercialModule.classifications)

  watchEffect(() => store.dispatch('commercialModule/getSubClassification', { id: commercialDetailsData.value.class_id }))

  const getSubClass = computed(() => store.state.commercialModule.subclassifications)

  const autocomplete = ref(null)
  const reflocation = ref(null)

  const items = ref([
    {
      id: '1',
      name: 'Freelancer',
      options: ['O1', 'O2', 'O3'],
    },
    {
      id: '2',
      name: 'Business',
      options: ['O4', 'O5', 'O6'],
    },
  ])

  return {
    reflocation,
    paymentDetailsData,
    commercialDetailsData,
    items,
    addCommercial,
    languages,
    tags,
    DC,
    classifications,
    getSubClass,
    autocomplete,
    toast,
  }
}
