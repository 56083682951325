import axios from '@axios'

export default {
  namespaced: true,
  state: {
    languages: [],
    tags: [],
    donationCategories: [],
    classifications: [],
    subclassifications: [],
  },
  getters: {},
  mutations: {
    GET_LANGUAGES(state, languages) {
      state.languages = languages
    },
    GET_TAGS(state, tags) {
      state.tags = tags
    },
    GET_DONATION_CATEGORIES(state, DC) {
      state.donationCategories = DC
    },
    GET_CLASS(state, classifications) {
      state.classifications = classifications
    },
    GET_SUBCLASS(state, subclass) {
      state.subclassifications = subclass
    },
  },
  actions: {
    getTags({ commit }) {
      axios.get('http://entities-microsevice.muslimdo.com/api/internalops/commercial_entity/getTags')
        .then(response => {
          commit('GET_TAGS', response.data)
        }).catch(error => console.log(error))
    },
    getLanguages({ commit }) {
      axios.get('http://entities-microsevice.muslimdo.com/api/internalops/commercial_entity/getLanguages').then(response => {
        commit('GET_LANGUAGES', response.data)
      }).catch(error => console.log(error))
    },
    getClassification({ commit }) {
      axios
        .get('http://entities-microsevice.muslimdo.com/api/internalops/commercial_entity/getClassifications')
        .then(response => {
          commit('GET_CLASS', response.data)
        })
        .catch(error => console.log(error))
    },
    getSubClassification({ commit }, { id }) {
      axios
        .get(`http://entities-microsevice.muslimdo.com/api/internalops/commercial_entity/getSubClassifications/${id}`)
        .then(response => {
          commit('GET_SUBCLASS', response.data)
        })
        .catch(error => console.log(error))
    },
    getDonationCategories({ commit }) {
      axios.get('http://entities-microsevice.muslimdo.com/api/internalops/commercial_entity/getDonationCategories')
        .then(response => {
          commit('GET_DONATION_CATEGORIES', response.data)
        })
        .catch(error => console.log(error))
    },
    addComm(ctx, commercialDetailsData) {
      return new Promise((resolve, reject) => {
        axios
          .post('http://entities-microsevice.muslimdo.com/api/internalops/commercial_entity', commercialDetailsData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
