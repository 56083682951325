<template>
  <div>
    <!-- {{ getSubClass }} -->
    <!-- BODY -->
    <form-wizard
      color="#00A5AF"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3 p-3"
      @on-complete="addCommercial()"
    >
      <tab-content
        title="Commercial Details"
        :before-change="validationForm"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <commercial-details
            ref="reflocations"
            :commercial-details="commercialDetailsData"
            :donation-categories="donationCategories"
            :classifications="classifications"
            :sub-classifications="getSubClass"
          />
        </validation-observer>
      </tab-content>
      <tab-content
        title="Second"
        :before-change="validationFormInfo"
      >
        <validation-observer
          ref="formInfo"
          tag="form"
        >
          <commercial-seconde
            :commercial-details="commercialDetailsData"
            :options="languages"
            :tag-options="tags"
            :donation-categories="DC"
          />
        </validation-observer>
      </tab-content>
      <tab-content
        title="Payment Details"
      >
        <payment-details
          :payment-details-data="commercialDetailsData"
        />
      </tab-content>
      <tab-content
        title="Finish"
      >
        <commercial-add-last
          :commercial-add-last-data="commercialAddLastData"
        />
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, onUnmounted, reactive, computed,
} from '@vue/composition-api'
import store from '@/store'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { FormWizard, TabContent } from 'vue-form-wizard'
// eslint-disable-next-line no-unused-vars
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'

// eslint-disable-next-line no-unused-vars
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, alphaNum, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import commercial from './commercial'
import commercialDetails from './CommercialDetails.vue'
import commercialSeconde from './CommercialSeconde.vue'
import paymentDetails from './PaymentDetails.vue'
import commercialAddLast from './CommercialAddLast.vue'
import commercialModule from './CommercialModule'

Vue.use(VueSweetalert2)

export default {
  components: {
    FormWizard,
    TabContent,
    commercialDetails,
    commercialSeconde,
    paymentDetails,
    commercialAddLast,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      variant: 'dark',
      typeOptions: [],
      classificationOptions: [],
      autocomplete: null,
      place: null,
    }
  },
  setup() {
    const COMMERCIAL_MODULE = 'commercialModule'

    // Register module
    if (!store.hasModule(COMMERCIAL_MODULE)) store.registerModule(COMMERCIAL_MODULE, commercialModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COMMERCIAL_MODULE)) store.unregisterModule(COMMERCIAL_MODULE)
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const {
      donationCategories,
      paymentDetailsData,
      commercialDetailsData,
      addCommercial,
      languages,
      tags,
      DC,
      classifications,
      subClass,
      form,
      fetSubCla,
      ddd,
      getSubClass,
    } = commercial()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      donationCategories,
      paymentDetailsData,
      commercialDetailsData,
      addCommercial,
      languages,
      tags,
      DC,
      classifications,
      subClass,
      form,
      fetSubCla,
      ddd,
      getSubClass,
    }
  },
  mounted() {
    this.initAutocomplete()
  },
  methods: {
    addNewORG() {
      axios.post('http://entities-microsevice.muslimdo.com/api/internalops/entity', this.formData).then(res => {
        if (res.data.status === 200) {
          console.log(res.data)
          this.show = false
          this.$swal(
            'Added!',
            'New Organization has been Added.',
            'success',
          )
          this.$parent.$refs.table.refresh()
        // eslint-disable-next-line no-empty
        } else {}
      }).catch(err => {
        if (err.response.data.errors.name) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.errors.name.toString(),
              icon: 'BellIcon',
            },
          })
        } if (err.response.data.errors.phone_number) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.errors.phone_number.toString(),
              icon: 'BellIcon',
            },
          })
        } if (err.response.data.errors.email) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.errors.email.toString(),
              icon: 'BellIcon',
            },
          })
        }
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.formInfo.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    initAutocomplete() {
      // eslint-disable-next-line no-undef
      this.autocomplete = new google.maps.places.Autocomplete(this.$refs.reflocations.$refs.reflocation, {
        componentRestrictions: { country: ['us', 'ca'] },
        fields: ['address_components', 'geometry'],
        types: ['address'],
      })
      console.log(this.autocomplete)
      this.autocomplete.addListener('place_changed', this.fillInAddress)
    },
    fillInAddress() {
      const place = this.autocomplete.getPlace()
      this.place = place
      console.log(place)
      // eslint-disable-next-line no-restricted-syntax
      for (const component of this.place.address_components) {
        const componentType = component.types[0]
        // eslint-disable-next-line default-case
        switch (componentType) {
          case 'street_number': {
            this.commercialDetailsData.street = `${component.long_name}, `
            break
          }
          case 'route': {
            this.commercialDetailsData.street += component.short_name
            break
          }
          case 'postal_code': {
            this.commercialDetailsData.postal_code = component.long_name
            break
          }
          case 'locality':
            this.commercialDetailsData.city = component.long_name
            break
          case 'administrative_area_level_1': {
            this.commercialDetailsData.state = component.short_name
            break
          }
          case 'country':
            this.commercialDetailsData.country = component.short_name
            break
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

</style>
