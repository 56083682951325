<template>
  <validation-observer>
    <b-row>
      <!-- Location -->
      <b-col md="4">
        <validation-provider
          #default="validationContext"
          name="Location"
        >
          <b-form-group
            label="Location"
            label-for="location"
          >
            <input
              id="location"
              ref="reflocation"
              v-model="location.full"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Type Your Location"
              class="form-control"
            >
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Street -->
      <b-col md="4">
        <validation-provider
          #default="validationContext"
          name="street"
        >
          <b-form-group
            label="Street"
            label-for="street"
          >
            <b-form-input
              id="street"
              v-model="location.street"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Street"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- City -->
      <b-col md="4">
        <validation-provider
          #default="validationContext"
          name="city"
        >
          <b-form-group
            label="City"
            label-for="city"
          >
            <b-form-input
              id="city"
              v-model="location.city"
              :state="getValidationState(validationContext)"
              trim
              placeholder="City"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- State -->
      <b-col md="4">
        <validation-provider
          #default="validationContext"
          name="city"
        >
          <b-form-group
            label="Province / State"
            label-for="state"
          >
            <b-form-input
              id="state"
              v-model="location.state"
              :state="getValidationState(validationContext)"
              trim
              placeholder="State"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Country -->
      <b-col md="4">
        <validation-provider
          #default="validationContext"
          name="city"
        >
          <b-form-group
            label="Country"
            label-for="country"
          >
            <b-form-input
              id="country"
              v-model="location.country"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Country"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Postal Code -->
      <b-col md="4">
        <validation-provider
          #default="validationContext"
          name="postalCode"
        >
          <b-form-group
            label="Zip / Postal Code"
            label-for="postalCode"
          >
            <b-form-input
              id="postalCode"
              v-model="location.postal_code"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Zip / Postal Code"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormGroup, BFormInput, BFormInvalidFeedback, BFormSelect, BCol, BRow,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { ref, reactive, watch } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import commercial from './commercial'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    BFormSelect,
  },
  props: {
    location: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const autoComplete = ref(null)
    const place = ref(null)

    const { reflocation, initAutocomplete } = commercial()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      autoComplete,
      place,
      reflocation,
      initAutocomplete,
    }
  },
  mounted() {
    this.initAutocomplete()
    console.log(this.location)
  },
  // methods: {
  //   initAutocomplete() {
  //     // eslint-disable-next-line no-undef
  //     this.autocomplete = new google.maps.places.Autocomplete(this.$refs.reflocation, {
  //       componentRestrictions: { country: ['us', 'ca'] },
  //       fields: ['address_components', 'geometry'],
  //       types: ['address'],
  //     })
  //     console.log(this.autocomplete)
  //     this.autocomplete.addListener('place_changed', this.fillInAddress)
  //   },
  //   fillInAddress() {
  //     const place = this.autocomplete.getPlace()
  //     this.place = place
  //     console.log(place)
  //     // eslint-disable-next-line no-restricted-syntax
  //     for (const component of this.place.address_components) {
  //       const componentType = component.types[0]
  //       // eslint-disable-next-line default-case
  //       switch (componentType) {
  //         case 'street_number': {
  //           this.location.streetNumber = `${component.long_name}, `
  //           break
  //         }
  //         case 'route': {
  //           this.location.streetNumber += component.short_name
  //           break
  //         }
  //         case 'postal_code': {
  //           this.location.postalCode = component.long_name
  //           break
  //         }
  //         case 'locality':
  //           this.location.city = component.long_name
  //           break
  //         case 'administrative_area_level_1': {
  //           this.location.state = component.short_name
  //           break
  //         }
  //         case 'country':
  //           this.location.country = component.short_name
  //           break
  //       }
  //       this.location.full = `${this.location.streetNumber}, ${this.location.city}, ${this.location.state}, ${this.location.country}, ${this.location.postalCode},`
  //     }
  //   },
  // },
}
</script>

<style>

</style>
